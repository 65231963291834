import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import "./App.css";
import Sidenav1 from "./Component/sidenav";
import Overview from "./Component/Overview";
import Login from "./Component/Login";
import Signup from "./Component/Signup";
import SubScription from "./Component/Subscriber";
import PrivateRoute from "./PrivateRoute";
import CookieConsent from "./Component/Cookies";

export default function App() {
  const location = useLocation();
  const excludeRoutes = ["/", "/Signup"];
  const shouldRenderSidenav = !excludeRoutes.includes(location.pathname);

  const userData = JSON.parse(localStorage.getItem("userData"));


  return (
    <>
      <div className="App">
        {shouldRenderSidenav && (
          <div className="sidenav-container">
            <Sidenav1 />
          </div>
        )}
        <main>




          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Login />} />
            <Route path="/Signup" element={<Signup />} />

            {/* Private Routes */}
            {userData ? (
              <>
                <Route path="/subscriber" element={<PrivateRoute><SubScription /></PrivateRoute>} />
                <Route path="/dashboard" element={<PrivateRoute><Overview /></PrivateRoute>} />
                <Route path="/Signup" element={<PrivateRoute><Signup /></PrivateRoute>} />
                {/* <Route path="/coupon" element={<PrivateRoute><Coupon /></PrivateRoute>} /> */}
                
                <Route path="/cookies" element={<PrivateRoute><CookieConsent /></PrivateRoute>} />
              </>
            ) : (

              <Route path="*" element={<Navigate to="/" />} />
            )}
          </Routes>
        </main>{" "}
      </div>
    </>
  );
}
